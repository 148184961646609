.about {
  .about-intro {
    padding-top: 80px;
    padding-bottom: 60px;

    @include mq-up($sm) {
      padding-top: 160px;
      padding-bottom: 130px;
    }

    .inner {
      margin-left: auto;
      margin-right: auto;
      max-width: 700px;
      text-align: center;
    }
  }

  .about-content {
    position: relative;

    @include mq-down($md) {
      background-color: #000;
    }

    .section-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      position: relative;
      z-index: 5;
    }

    .inner {
      padding: 40px 0;
      color: #f3f3f3;

      @include mq-up($md) {
        padding: 12.5% 11.25%;
        width: 52.5%;
        min-height: 650px;
        background-color: #000;
      }

      > p {
        &:first-child {
          font-weight: 400;
        }
      }
    }

    .bg {
      @include mq-down($md) {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        height: 0;
        padding-top: 56.25%;
      }
    }
  }
}
