.leaderboard {
  position: relative;
  background-color: #000;

  .section-wrapper {
    position: relative;
    z-index: 5;
  }

  .inner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;
    min-height: 100vh;
  }

  h1 {
    color: #fff;

    span {
      font-family: $ff-body;
      font-weight: 200;
      letter-spacing: 0;

      @include mq-up($sm) {
        letter-spacing: 0.075em;
      }
    }

    strong {
      display: block;
      letter-spacing: 0;
    }
  }

  .bg {
    opacity: 0.35;
  }
}
