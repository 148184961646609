// -------------------------------------------------------------
//   Buttons
// -------------------------------------------------------------
.btn {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  padding: 0.6rem 1.2rem;
  background-color: transparent;
  box-shadow: none !important;
  color: $body-color;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  outline: 0 !important;
  user-select: none;
  transition: all 0.15s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  &.btn-primary,
  &.btn1 {
    border-color: $primary;
    background-color: $primary;
    color: #fff;
    &:hover {
      border-color: darken($primary, 10%);
      background-color: darken($primary, 7.5%);
      color: #fff;
    }
  }

  &.btn-light {
    border-color: $light;
    background-color: $light;
    color: #212529;
    &:hover {
      border-color: darken($light, 10%);
      background-color: darken($light, 7.5%);
      color: #212529;
    }
  }

  &.btn-dark {
    border-color: $dark;
    background-color: $dark;
    color: #fff;
    &:hover {
      border-color: darken($dark, 10%);
      background-color: darken($dark, 7.5%);
      color: #fff;
    }
  }

  &.btn-outline-primary,
  &.btn3 {
    border-color: $primary;
    color: $primary;
    &:hover {
      border-color: $primary;
      background-color: $primary;
      color: #fff;
    }
  }

  &.btn-outline-light {
    border-color: $light;
    color: $light;
    &:hover {
      border-color: $light;
      background-color: $light;
      color: #212529;
    }
  }

  &.btn-outline-dark {
    border-color: $dark;
    color: $dark;
    &:hover {
      border-color: $dark;
      background-color: $dark;
      color: #fff;
    }
  }

  &.btn-link {
    color: $primary;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      color: darken($primary, 15%);
      text-decoration: underline;
    }
  }
}

input[type="submit"] {
  @extend .btn;
  @extend .btn-primary;
}
