// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: $font-base;
}

body > svg {
  display: none;
}

body {
  background-color: $body-bg;
  color: $body-color;
  font-family: $ff-body;
  font-size: 1.4rem;
  font-weight: 200;
  letter-spacing: 0.075em;
  line-height: 2;
}

.section-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  @include mq-up($sm) {
    max-width: $sm;
  }
  @include mq-up($md) {
    max-width: $md;
  }
  @include mq-up($lg) {
    max-width: $lg;
  }
  @include mq-up($xl) {
    max-width: $xl;
  }
  @include mq-up($xxl) {
    max-width: $xxl;
  }
  &.sm {
    @include mq-up($sm) {
      max-width: $sm;
    }
  }
  &.md {
    @include mq-up($md) {
      max-width: $md;
    }
  }
  &.lg {
    @include mq-up($lg) {
      max-width: $lg;
    }
  }
  &.xl {
    @include mq-up($xl) {
      max-width: $xl;
    }
  }
  &.xxl {
    @include mq-up($xxl) {
      max-width: $xxl;
    }
  }
}

.iframe-embed {
  display: block;
  position: relative;
  margin-bottom: 30px;
  padding: 0;
  width: 100%;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    display: block;
    padding-top: 56.25%;
  }

  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }
}

.bg {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
