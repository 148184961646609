.services {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #f3f5f8;

  @include mq-up($sm) {
    padding-top: 160px;
  }

  h2 {
    margin-bottom: 40px;
    text-align: center;

    @include mq-up($sm) {
      margin-bottom: 100px;
    }
  }

  .services-list {
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;

    @include mq-up($md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      margin: 50px 0;
      padding-left: 40px;
      background-position: left 8px top;
      background-repeat: no-repeat;
      background-size: 24px auto;
      color: #000;
      font-family: $ff-headings;
      font-size: 1.6rem;
      font-weight: 700;
      letter-spacing: 0.15em;
      line-height: 1.575;
      text-transform: uppercase;

      @include mq-up($sm) {
        padding-left: 70px;
        padding-right: 30px;
        background-position: left 38px top;
      }

      @include mq-up($md) {
        flex: 0 0 50%;
        max-width: 50%;
        width: 100%;
      }

      @include mq-up($lg) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }

      &:nth-child(1) {
        background-image: url(../images/icon-service01.png);
      }

      &:nth-child(2) {
        background-image: url(../images/icon-service02.png);
      }

      &:nth-child(3) {
        background-image: url(../images/icon-service03.png);
      }

      &:nth-child(4) {
        background-image: url(../images/icon-service04.png);
      }

      &:nth-child(5) {
        background-image: url(../images/icon-service05.png);
      }

      &:nth-child(6) {
        background-image: url(../images/icon-service06.png);
      }

      &:nth-child(7) {
        background-image: url(../images/icon-service07.png);
      }

      &:nth-child(8) {
        background-image: url(../images/icon-service08.png);
      }
    }
  }
}
