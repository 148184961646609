// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------

.main-header {
  position: absolute;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;

  .logo {
    display: block;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}
