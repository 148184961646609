// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------

.main-footer {
  padding-top: 40px;
  padding-bottom: 20px;
  color: #999;
  font-size: 1.2rem;
  text-align: center;

  &::before {
    content: "";
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 15px;
    height: 1px;
    background-color: #c4c2c2;
  }
}
