.contact {
  padding-top: 120px;
  padding-bottom: 40px;
  background-color: #f3f5f8;

  h2 {
    text-align: center;

    @include mq-up($sm) {
      font-size: 3.4rem;
    }

    &::after {
      content: "";
      display: block;
      margin-top: 25px;
      margin-left: auto;
      margin-right: auto;
      width: 60px;
      height: 1px;
      background-color: #000;
    }
  }

  .subtitle {
    margin-bottom: 25px;
    text-align: center;
  }

  .coords {
    @include mq-up($md) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
    }

    .coord {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 60px;
      background-position: center top;
      background-repeat: no-repeat;
      line-height: 1.375;
      text-align: center;

      @include mq-up($sm) {
        padding-top: 0;
        padding-left: 105px;
        padding-right: 30px;
        background-position: left 30px top 25px;
        text-align: left;
      }

      @include mq-up($md) {
        flex: 0 0 50%;
        max-width: 50%;
        width: 100%;
      }

      @include mq-up($lg) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }

      .inner_coord {
        border-top: 1px solid #d5d5d5;
        padding: 20px 0;

        @include mq-up($sm) {
          border-top: 0;
          border-left: 1px solid #d5d5d5;
          padding-left: 25px;
        }
      }

      h3 {
        margin-bottom: 15px;
        font-size: 1.6rem;
        font-weight: 400;
      }

      &.address {
        background-image: url(../images/icon-map.png);
      }

      &.mail {
        background-image: url(../images/icon-plane.png);
      }

      &.phone {
        background-image: url(../images/icon-phone.png);
      }
    }
  }
}
