.clients {
  padding-top: 80px;
  padding-bottom: 80px;

  @include mq-up($sm) {
    padding-top: 140px;
    padding-bottom: 150px;
  }

  h2 {
    margin-bottom: 40px;
    text-align: center;

    @include mq-up($sm) {
      margin-bottom: 100px;
      font-size: 3.4rem;
    }

    &::after {
      content: "";
      display: block;
      margin-top: 25px;
      margin-left: auto;
      margin-right: auto;
      width: 60px;
      height: 1px;
      background-color: #000;
    }
  }

  .swiper-container {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 100%;
    height: auto;
  }
  .swiper-slide {
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: auto;
      opacity: 0.4;
      filter: grayscale(100%);
    }
  }
  .swiper-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .swiper-pagination-bullet {
    display: block;
    margin: 0 10px !important;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #d5d5d5;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: #000;
    opacity: 1;
  }
}
