.copyright-exp {
  display: inline-block;
  padding-right: 30px;
  position: relative;

  span {
    display: block;
    transition: opacity 0.2s ease-in 1.3s;
  }

  svg {
    position: absolute;
    top: 5px;
    left: 15%;
    display: block;
    width: auto;
    height: 24px;
    overflow: visible;
    transition: left 0.2s ease-in 0.7s, transform 0.2s ease-in 0.7s;

    #e {
      opacity: 0;
      transition: opacity 0.2s ease-in 0.5s;
    }

    #X {
      transform: translate3d(83%, 0, 0);
      transition: transform 0.5s ease-in 0.7s;
    }

    #pansion {
      clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
      transition: clip-path 0.5s ease-in 0.7s;
    }

    #baseline {
      transform: translate3d(0, -10%, 0);
      opacity: 0;
      transition: transform 0.2s ease-in, opacity 0.2s ease-in;
    }
  }

  &:hover {
    span {
      opacity: 0;
      transition: none;
    }

    svg {
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      transition: left 0.2s ease-in, transform 0.2s ease-in;
      #e {
        opacity: 1;
        transition: opacity 0.2s ease-in 0.5s;
      }
      #X {
        transform: translate3d(0, 0, 0);
        transition: transform 0.5s ease-in;
      }
      #pansion {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        transition: clip-path 0.5s ease-in;
      }
      #baseline {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: transform 0.2s ease-in 1s, opacity 0.2s ease-in 1s;
      }
    }
  }
}
