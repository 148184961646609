// -------------------------------------------------------------
//   Types
// -------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 20px;
  color: $primary;
  font-family: $ff-headings;
  font-weight: 700;
  letter-spacing: 0.075em;
  line-height: 1.1;
  text-transform: uppercase;

  @include mq-up($sm) {
    letter-spacing: 0.15em;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
.h1 {
  font-size: 3.6rem;

  @include mq-up($sm) {
    font-size: 5rem;
  }

  @include mq-up($md) {
    font-size: 6rem;
  }
}

h2,
.h2 {
  font-size: 3rem;

  @include mq-up($sm) {
    font-size: 3.6rem;
  }

  @include mq-up($md) {
    font-size: 4.2rem;
  }
}

h3,
.h3 {
  font-size: 2.4rem;

  @include mq-up($md) {
    font-size: 3.6rem;
  }
}

h4,
.h4 {
  font-size: 2rem;
}

h5,
.h5 {
  font-size: 1.6rem;
}

h6,
.h6 {
  font-size: 1.2rem;
}

p,
ul,
ol {
  margin-top: 0;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.subtitle {
  font-size: 2rem;
  line-height: 1.6;
}

.lead {
  font-size: 2rem;
  font-weight: 300;
}

.evidence {
  color: $primary;
  font-size: 2rem;
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0;
  border-top: 1px solid #999;
}
