.sliding-popup-bottom,
.sliding-popup-top {
  left: 0;
  padding: 30px 0;
  width: 100%;
  background-color: $primary;
  color: #fff;

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    color: inherit;
  }

  p,
  ul,
  ol {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a:not([class*="btn"]) {
    color: inherit;
  }

  .find-more-button {
    @extend .btn;
    @extend .btn-outline-light;
    &.hidden {
      display: none;
    }
  }
  .agree-button,
  .eu-cookie-withdraw-button {
    @extend .btn;
    @extend .btn-light;
    &.hidden {
      display: none;
    }
  }
  .decline-button,
  .eu-cookie-compliance-save-preferences-button {
    @extend .btn;
    @extend .btn-link;
    color: #fff !important;
    &.hidden {
      display: none;
    }
  }
}

.eu-cookie-compliance-content {
  display: block;
}
.eu-cookie-compliance-message {
  @include mq-down($md) {
    padding-bottom: 20px;
  }
}
.eu-cookie-withdraw-tab {
  @extend .btn;
  @extend .btn-light;
  border-radius: 10px 10px 0 0;
  padding: 0px 5px;
  background-image: none;
  text-transform: none;
}

.eu-cookie-compliance-banner--categories {
  .eu-cookie-compliance-categories {
    @include mq-up($md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      float: right;
      width: 100%;
      max-width: 30%;

      > * {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .eu-cookie-compliance-buttons {
    padding-top: 20px;
    clear: both;
    float: none;
    max-width: none;
  }
}
