// -------------------------------------------------------------
//   Variables
// -------------------------------------------------------------

// Color system

$primary: #000;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;

$body-bg: #fff;
$body-color: #333;

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap");
$ff-headings: "Montserrat", sans-serif;
$ff-body: "Raleway", sans-serif;
$font-base: 62.5%;

// Grid

$xs: 0;
$sm: 560px;
$md: 800px;
$lg: 1000px;
$xl: 1200px;
$xxl: 1440px;
